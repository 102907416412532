import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Fuse from 'fuse.js';
import './BriefsHome.css';
import linksData from './content_items.json';

const BriefsHome = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialCategory = queryParams.get('category');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategories, setSelectedCategories] = useState(initialCategory ? { [initialCategory]: true } : {});
    const [filteredLinks, setFilteredLinks] = useState(linksData);
    const [iframeUrl, setIframeUrl] = useState('');
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const uniqueCategories = Array.from(new Set(linksData.flatMap(link => link.categories)));

    useEffect(() => {
        // Initialize Fuse for fuzzy search
        const fuse = new Fuse(linksData, {
            keys: ['title', 'categories', 'author'],
            threshold: 0.3
        });

        const filtered = linksData.filter(link =>
            link.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
            Object.keys(selectedCategories).filter(cat => selectedCategories[cat]).every(cat => link.categories.includes(cat))
        );

        const searchResults = searchTerm ? fuse.search(searchTerm).map(({ item }) => item) : linksData;

        const combinedResults = searchResults.filter(link =>
            Object.keys(selectedCategories).filter(cat => selectedCategories[cat]).every(cat => link.categories.includes(cat))
        );

        setFilteredLinks(combinedResults);

        // If the component is loaded with a specific brief ID, find and display that brief
        if (id) {
            const brief = linksData.find(link => link.id === id);
            if (brief) {
                setIframeUrl(brief.url);
                setIsPopupVisible(true);
            }
        } else {
            setIsPopupVisible(false);
        }
    }, [searchTerm, selectedCategories, id]);

    const handleTagButtonClick = (category) => {
        if (category === "Show All") {
            setSelectedCategories(prev => (Object.keys(prev).length === 0 ? prev : {}));
        } else {
            setSelectedCategories({ [category]: true });
        }
    };

    const renderTagButtons = () => {
        const allTags = ["Show All", "Biographical Sketches", "Key Scenes", "Keywords", "Novel Adaptations", "Book & Literary History", "Literary Data Work", "Style & Structure", "Lists", "Reading & Teaching Fiction", "Audio-Visual Gallery"];
        
        return allTags.map(tag => (
            <TagButton
                key={tag}
                category={tag}
                isSelected={tag === "Show All" ? Object.keys(selectedCategories).length === 0 : !!selectedCategories[tag]}
                onClick={() => handleTagButtonClick(tag)}
            >
                {tag}
            </TagButton>
        ));
    };

    const handleCategoryChange = (category) => {
        setSelectedCategories(prev => ({
            ...prev,
            [category]: !prev[category]
        }));
    };

    const TagButton = ({ category, children, isSelected, onClick }) => (
        <button onClick={onClick} style={{
            display: 'flex',
            padding: '6px 12px',
            alignItems: 'center',
            gap: '4px',
            borderRadius: '8px',
            border: '0.25px solid var(--Gray-Black, #00000090)',
            background: isSelected ? 'white' : 'transparent',
            cursor: 'pointer',
            color: '#00000090'
        }}>
            {children}
        </button>
    );

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const handleLinkClick = (url) => {
        setIframeUrl(url);
        setIsPopupVisible(true);
        navigate('/briefs', { replace: true });
    };

    const closePopup = () => {
        setIsPopupVisible(false);
        // Remove the ID from the URL without reloading the page
        navigate('/briefs', { replace: true });
    };

    return (
        <div className="BriefsWrapper">
            {isVisible && (
                <button
                    className="back-to-top-button"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Back to top">
                    <span className="back-to-top-text">Back to Top</span>
                </button>
            )}

            <div className="filter-bar">
                <div className="filter-bar-header">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
  <path d="M19.9403 4.1261C20.1226 4.1261 20.2975 4.19853 20.4264 4.32746C20.5554 4.45639 20.6278 4.63126 20.6278 4.8136V17.1886C20.6278 17.3709 20.5554 17.5458 20.4264 17.6747C20.2975 17.8037 20.1226 17.8761 19.9403 17.8761H2.06531C1.88297 17.8761 1.7081 17.8037 1.57917 17.6747C1.45024 17.5458 1.37781 17.3709 1.37781 17.1886V4.8136C1.37781 4.63126 1.45024 4.45639 1.57917 4.32746C1.7081 4.19853 1.88297 4.1261 2.06531 4.1261H19.9403ZM2.06531 2.7511C1.5183 2.7511 0.993694 2.9684 0.6069 3.35519C0.220106 3.74198 0.00280762 4.26659 0.00280762 4.8136V17.1886C0.00280762 17.7356 0.220106 18.2602 0.6069 18.647C0.993694 19.0338 1.5183 19.2511 2.06531 19.2511H19.9403C20.4873 19.2511 21.0119 19.0338 21.3987 18.647C21.7855 18.2602 22.0028 17.7356 22.0028 17.1886V4.8136C22.0028 4.26659 21.7855 3.74198 21.3987 3.35519C21.0119 2.9684 20.4873 2.7511 19.9403 2.7511H2.06531Z" fill="white"/>
  <path d="M4.12781 7.5636C4.12781 7.38126 4.20024 7.20639 4.32917 7.07746C4.4581 6.94853 4.63297 6.8761 4.81531 6.8761H17.1903C17.3726 6.8761 17.5475 6.94853 17.6764 7.07746C17.8054 7.20639 17.8778 7.38126 17.8778 7.5636C17.8778 7.74593 17.8054 7.9208 17.6764 8.04973C17.5475 8.17867 17.3726 8.2511 17.1903 8.2511H4.81531C4.63297 8.2511 4.4581 8.17867 4.32917 8.04973C4.20024 7.9208 4.12781 7.74593 4.12781 7.5636ZM4.12781 11.0011C4.12781 10.8188 4.20024 10.6439 4.32917 10.515C4.4581 10.386 4.63297 10.3136 4.81531 10.3136H17.1903C17.3726 10.3136 17.5475 10.386 17.6764 10.515C17.8054 10.6439 17.8778 10.8188 17.8778 11.0011C17.8778 11.1834 17.8054 11.3583 17.6764 11.4872C17.5475 11.6162 17.3726 11.6886 17.1903 11.6886H4.81531C4.63297 11.6886 4.4581 11.6162 4.32917 11.4872C4.20024 11.3583 4.12781 11.1834 4.12781 11.0011ZM4.12781 14.4386C4.12781 14.2563 4.20024 14.0814 4.32917 13.9525C4.4581 13.8235 4.63297 13.7511 4.81531 13.7511H13.0653C13.2476 13.7511 13.4225 13.8235 13.5514 13.9525C13.6804 14.0814 13.7528 14.2563 13.7528 14.4386C13.7528 14.6209 13.6804 14.7958 13.5514 14.9247C13.4225 15.0537 13.2476 15.1261 13.0653 15.1261H4.81531C4.63297 15.1261 4.4581 15.0537 4.32917 14.9247C4.20024 14.7958 4.12781 14.6209 4.12781 14.4386Z" fill="white"/>
</svg>
Briefs
                </div>
                <div className="Briefs-Filter-bar">
                    <div className="BriefsSearchContainer">
                        <input 
                            className="BriefsSearchBox"
                            type="text" 
                            placeholder="Author, Title, Topic, Keyword" 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} 
                        />
                        <button type="submit" className='search-button'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M16.1481 14.2239C17.4795 12.4071 18.0758 10.1545 17.8177 7.91695C17.5597 5.67936 16.4663 3.62171 14.7562 2.15567C13.0462 0.689637 10.8457 -0.0766745 8.59497 0.0100511C6.34422 0.0967767 4.2092 1.03014 2.61707 2.62342C1.02493 4.21669 0.0930909 6.35238 0.0079759 8.60319C-0.0771391 10.854 0.690747 13.054 2.15801 14.7629C3.62527 16.4719 5.6837 17.5638 7.92147 17.8203C10.1592 18.0768 12.4113 17.4788 14.2272 16.1461H14.2258C14.2671 16.2011 14.3111 16.2534 14.3606 16.3042L19.6543 21.598C19.9121 21.856 20.2619 22.001 20.6266 22.0011C20.9914 22.0013 21.3413 21.8565 21.5993 21.5987C21.8573 21.3409 22.0023 20.9911 22.0024 20.6264C22.0025 20.2616 21.8578 19.9117 21.5999 19.6537L16.3062 14.36C16.257 14.3102 16.2042 14.2643 16.1481 14.2225V14.2239ZM16.5028 8.93837C16.5028 9.93149 16.3072 10.9149 15.9272 11.8324C15.5471 12.7499 14.9901 13.5836 14.2878 14.2859C13.5856 14.9881 12.7519 15.5452 11.8344 15.9252C10.9168 16.3053 9.93344 16.5009 8.94032 16.5009C7.9472 16.5009 6.9638 16.3053 6.04628 15.9252C5.12875 15.5452 4.29507 14.9881 3.59283 14.2859C2.89058 13.5836 2.33353 12.7499 1.95348 11.8324C1.57343 10.9149 1.37782 9.93149 1.37782 8.93837C1.37782 6.93267 2.17458 5.00912 3.59283 3.59088C5.01107 2.17263 6.93462 1.37587 8.94032 1.37587C10.946 1.37587 12.8696 2.17263 14.2878 3.59088C15.7061 5.00912 16.5028 6.93267 16.5028 8.93837V8.93837Z" fill="#765623"/>
                        </svg>
                        </button>
                    </div>
                    <div className="BriefsFilters">
                        {uniqueCategories.map(category => (
                            <div key={category} className="Briefs-select">
                                <label>
                                    <input 
                                        type="checkbox" 
                                        checked={!!selectedCategories[category]}
                                        onChange={() => handleCategoryChange(category)}
                                    /> {category}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="Briefs-render">
                <div className="Briefs-info">
                    
                    <p className="featured-subtitle" style= {{display: "flex", gap: "1rem"}} ><img src="/info.svg" />      Introduction</p>
                    <p><br/></p>
                    <p >The Multithreaded Literary Briefs (Briefs) includes interrelated commentary about African American fiction composed by a team of literature scholar-connectors. This commentary highlights relationships between various novels and novelists that constitute an associated network of ideas and focal subjects.</p>
                    <p><br/></p>

                    <p><br/></p>
                    <div className="BriefsToggleButtons">
                        {renderTagButtons()}
                    </div>
                    <p><br/></p>
                </div>
                <div className="Briefs-Results">
                    {filteredLinks.map((link, index) => (
                        <div className="Briefs-R" key={index}>
                            <div onClick={(e) => { e.preventDefault(); handleLinkClick(link.url); }} style={{ cursor: 'pointer' }}>
                                <p>{link.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {isPopupVisible && (
                <div className="PopupOverlay">
                    <div className="PopupContent">
                        <iframe src={iframeUrl} name="iframe-content" width="100%" height="100%"></iframe>
                        <button className="CloseButton" onClick={closePopup}>X</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BriefsHome;
