import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';

import './PopularNovels.css';

import {linkUrl} from './linkUrl';




const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const PopularNovels = ({ isDisplayingFeatured, onNovelClick }) => {
  const [popularNovels, setPopularNovels] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const fetchNovels = async () => {
      try {
        const response = await axios.post(linkUrl, {
          query: {
            match_all: {}
          },
          size: 100
        });

        if (response.data.hits.hits) {
          const novels = response.data.hits.hits;
          const shuffledNovels = shuffleArray(novels).slice(0, 15);
          setPopularNovels(shuffledNovels);
        }
      } catch (error) {
        console.error('Error fetching novels from Elasticsearch:', error);
      }
    };

    fetchNovels();
  }, []);

  const scrollLeft = () => {
    const container = document.querySelector('.popular-novels-container');
    const scrollAmount = container.clientWidth / 5;
    container.scrollLeft -= scrollAmount;
    setScrollPosition(container.scrollLeft - scrollAmount);
  };

  const scrollRight = () => {
    const container = document.querySelector('.popular-novels-container');
    const scrollAmount = container.clientWidth / 5;
    container.scrollLeft += scrollAmount;
    setScrollPosition(container.scrollLeft + scrollAmount);
  };

  const TagButton = ({ children }) => (
    <button style={{
      display: 'flex',
      padding: '6px 12px',
      alignItems: 'center',
      gap: '4px',
      borderRadius: '8px',
      border: '0.25px solid var(--Gray-Black, #00000090)',
      background: 'transparent',
      cursor: 'pointer',
      color: '#00000090',


      /* Small Shadow */
      /* Body/Regular */
      fontFamily: 'Avenir',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '350',
      lineHeight: '130%', 
      letterSpacing: '0.05rem',
      
      /* Small Shadow */
    }}>
      {children}
    </button>
  );

  return (
    <>
      {isDisplayingFeatured && (
        <div className='popular-topics'>
          <p className="section-title">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path d="M14.0938 8.9375C14.2519 9.41184 14.2521 9.41177 14.2523 9.41169L14.2528 9.41152L14.254 9.41112L14.2569 9.41013L14.265 9.40733L14.2898 9.3984C14.31 9.39094 14.3376 9.38041 14.3712 9.36662C14.4385 9.33907 14.5309 9.29824 14.6393 9.2425C14.8545 9.13181 15.1411 8.958 15.4184 8.70591C15.7568 8.39824 16.0813 7.97147 16.2436 7.41368C18.2246 9.86769 18.75 12.6794 18.75 14.4375C18.75 16.7052 17.8849 18.4553 16.507 19.645C15.1208 20.8419 13.1812 21.5 11 21.5C8.81884 21.5 6.87922 20.8419 5.49299 19.645C4.11513 18.4553 3.25 16.7052 3.25 14.4375C3.25 12.1296 4.23022 9.90614 5.81235 8.64043C7.60464 7.2066 8.48678 5.73901 8.83519 4.23806C9.11801 3.01966 9.03965 1.81437 8.86563 0.655693C10.453 1.13241 11.7931 2.28111 12.6815 3.7025C13.7007 5.33322 14.0789 7.24706 13.6148 8.79383L13.3381 9.71645L14.2519 9.41184L14.0938 8.9375Z" stroke="#1A1A1A"/>
            <path d="M11.8895 11.7354C11.8459 12.1985 11.8493 12.6833 11.9636 13.1756C12.1526 13.9891 12.6298 14.7691 13.5627 15.5154C14.2854 16.0937 14.75 17.1278 14.75 18.2188C14.75 19.2834 14.3465 20.0881 13.7151 20.6333C13.0753 21.1857 12.167 21.5 11.125 21.5C10.083 21.5 9.17466 21.1857 8.53488 20.6333C7.90348 20.0881 7.5 19.2834 7.5 18.2188C7.5 17.4594 7.70672 16.2845 8.44872 15.1989C8.55541 15.3888 8.68315 15.5504 8.80353 15.6801C8.97055 15.8599 9.14149 15.9989 9.26944 16.0925C9.33399 16.1397 9.38909 16.1765 9.42936 16.2022C9.44953 16.2151 9.46609 16.2253 9.47838 16.2327L9.49357 16.2418L9.49863 16.2447L9.5005 16.2458L9.50127 16.2462L9.50162 16.2464C9.50178 16.2465 9.50193 16.2466 9.75 15.8125L9.50193 16.2466L10.5885 16.8675L10.2289 15.6688C10.0178 14.9653 10.1509 13.984 10.6013 13.1223C10.9184 12.5156 11.3638 12.0216 11.8895 11.7354ZM9.99963 15.3793C9.99987 15.3794 9.99984 15.3794 9.99954 15.3792L9.99963 15.3793Z" stroke="#1A1A1A"/>
          </svg>
           Popular Topics</p>
          <div className='topic-buttons-container-nov-gen'>
          <TagButton>Black Thunder</TagButton>
          <TagButton>Freedom</TagButton>
          <TagButton>Collective Behavior</TagButton>
          <TagButton>Rebellion</TagButton>
          <TagButton>Virginia</TagButton>
          <TagButton>Bontemps, Arna</TagButton>
          <TagButton>Cincinnati</TagButton>
          <TagButton>Collective Behavior</TagButton>
          <TagButton>Collective Behavior</TagButton>
          <TagButton>Rebellion</TagButton>
          <TagButton>Bontemps, Arna</TagButton>
          <TagButton>Virginia</TagButton>
          <TagButton>Bontemps, Arna</TagButton>
          
            </div>
          <div className="popular-novels-wrapper">

          <button onClick={scrollLeft} className="nav-button-pop lft-btn">
            <img src='./leftArrow.svg'></img>
          </button>
            <div className='popular-novels-container'>
            
              {popularNovels.map((novel, index) => (
                <div key={index} className="popular-novel" onClick={() => onNovelClick(novel)}>
                  {novel._source.Bookcover && (
                    <img src={novel._source.Bookcover} alt={`Cover of ${novel._source["Book title"]}`} onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/placehold.svg`; }}/>
                  )}
                  <div className="popular-novel-info">
                  <i><p className="title">{novel._source["Book title"]}</p></i>
                  <p>{novel._source["Name as it appears"]}</p>
                  <p>{novel._source["Publication year"]}</p>
                    
                  </div>
                </div>
              ))}
              
            </div>
          <button onClick={scrollRight} className="nav-button-pop rt-btn">
            <img src='./rightArrow.svg'></img>
          </button>
          </div>
          </div>
          
          
      )}
    </>
  );
};

export default PopularNovels;
