import React from "react";
import './Info.css';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';

  
  const Tab = styled(BaseTab)`
    color: rgb(177, 131, 102);
    cursor: pointer;
    font-size: 0.875rem;
    background-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left:1rem;
    padding-right:1rem;

    margin: 6px;
    margin-left:0px;
    margin-right:0px;
    
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
  
    &:hover {
      color: rgb(218, 173, 145);
    }
  
    &:focus {
      color: #fff;
    }
  
    &.${tabClasses.selected} {
      background-color: #fff;
      color: black;
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;
  
  const TabPanel = styled(BaseTabPanel)(
    ({ theme }) => `

    font-size: 0.875rem;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
    padding-bottom: 20px;
    min-height: 100px;
    background-color: rgba(255, 255, 255, 0.88);
    margin-top: -23px;
    `,
  );
  
  const TabsList = styled(BaseTabsList)(
    ({ theme }) => `

    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    `,
  );

const Info = () => {
    return (
        <div className="Info-Container" id="Info">
            <div className="Info-header">
                <p className="Info-Title">What is Black Lit Network?</p>
            </div>
            <div className="Info-Content">
            <div className="Info-Img-div">
    <div className="image-container">
        <img src="BlackLit1.png" alt="Description of Image 1"></img>
        <p>Studying Black Writing</p>
    </div>
    <div className="image-container">
        <img src="BlackLit2.png" alt="Description of Image 2"></img>
        <p>7,000 Black novels and memoirs</p>
    </div>
    <div className="image-container">
        <img src="BlackLit3.png" alt="Description of Image 3"></img>
        <p>Support research and learning</p>
    </div>
</div>


          <p id="info-top-desc">Portals making ideas about African American novels and collections available through a podcast, visualizations, briefs, and interactive search engines.</p>
            </div>
            <div className="Info-header">
                <p className="Info-Title">How can I use this website?</p>
            </div>
            <div className="Info-Content-Bottom">
            <Tabs defaultValue={0}>
            <TabsList>
                <Tab value={0}>Educator</Tab>
                <Tab value={1}>Student</Tab>
                <Tab value={2}>Researcher</Tab>
                <Tab value={3}>Librarian</Tab>
                <Tab value={4}>Book Lover</Tab>
            </TabsList>
            <TabPanel value={0}>BBN is designed to make information and ideas about African American novels widely available. The portals for the Network address several basic yet enduring questions: What’s a good African American novel to read? What’s the story behind the publication of some famous novels by black writers? What’s the relationship of one novel to dozens of other novels? What key terms assist us in understanding the works of black writers?</TabPanel>
            <TabPanel value={1}>
              <div className="InfoPanel2">
                <img src="/Frame116.png"></img>
                <img src="/Frame117.png"></img>
                <img src="/Frame118.png"></img>
              </div>
              <p>HistoryofBlackWriting.org presents the work of educators, students, librarians, and researchers dedicated to studying Black writing</p>
            </TabPanel>
            <TabPanel value={2}>N/A</TabPanel>
            <TabPanel value={3}>N/A</TabPanel>
            <TabPanel value={4}>N/A</TabPanel>
            </Tabs>
            </div>
        </div>
    );
};

export default Info;