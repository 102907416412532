import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { linkUrl } from '../NovelGen/linkUrl';

// Define the TagButton component
const TagButton = ({ children, onClick }) => (
  <button
    onClick={onClick}
    style={{
      display: 'flex',
      padding: '6px 12px',
      alignItems: 'center',
      gap: '4px',
      borderRadius: '8px',
      border: '0.25px solid var(--Gray-Black, #00000090)',
      background: 'transparent',
      cursor: 'pointer',
      color: '#00000090'
    }}
  >
    {children}
  </button>
);

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const fetchAndSetRelatedNovels = async (dataItem, setRelatedNovels, setLoading) => {
  if (!dataItem) return;

  setLoading(true);

  const queryConditions = [];

  // Add conditions based on dataItem details if applicable
  if (dataItem.categories) {
    queryConditions.push({
      terms: { "Categories.keyword": dataItem.categories }
    });
  }

  if (dataItem.title) {
    queryConditions.push({
      multi_match: {
        query: dataItem.title,
        fields: ["Book title", "Name as it appears"]
      }
    });
  }

  // Customize the query conditions based on available dataItem information

  try {
    const response = await axios.post(linkUrl, {
      query: {
        bool: {
          should: queryConditions,
          minimum_should_match: 1,
        }
      },
      size: 100
    });

    if (response.data.hits.hits) {
      const novels = response.data.hits.hits.map(novel => {
        return {
          ...novel,
          _source: {
            ...novel._source,
            Bookcover: novel._source.Bookcover.replace(/^\./, '') // Remove leading dot if present
          }
        };
      });
      const shuffledNovels = shuffleArray(novels).slice(0, 15);
      setRelatedNovels(shuffledNovels);
    }
  } catch (error) {
    console.error('Error fetching related novels:', error);
    alert(`Error: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

const RelatedNovelsForDatavis = ({ dataItem }) => {
  const [relatedNovels, setRelatedNovels] = useState([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAndSetRelatedNovels(dataItem, setRelatedNovels, setLoading);
  }, [dataItem]);

  const scrollLeft = () => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth / 5;
      containerRef.current.scrollLeft -= scrollAmount;
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth / 5;
      containerRef.current.scrollLeft += scrollAmount;
    }
  };

  const handleTagClick = (tag) => {
    // Implement navigation or filtering logic based on the tag click
    console.log(`Tag clicked: ${tag}`);
  };

  const handleBookClick = (book) => {
    navigate(`/books/${book._id}`);
  };

  return (
    <div>
      <p className="section-title-related">
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
  <path d="M1.87781 3.88959C3.09468 3.38084 4.83956 2.83221 6.53631 2.66171C8.36506 2.47746 9.91606 2.74834 10.8153 3.69571V17.0965C9.52968 16.3677 7.90031 16.2673 6.39743 16.4186C4.77493 16.5836 3.13868 17.0525 1.87781 17.5337V3.88959ZM12.1903 3.69571C13.0896 2.74834 14.6406 2.47746 16.4693 2.66171C18.1661 2.83221 19.9109 3.38084 21.1278 3.88959V17.5337C19.8656 17.0525 18.2307 16.5822 16.6082 16.42C15.1039 16.2673 13.4759 16.3663 12.1903 17.0965V3.69571ZM11.5028 2.45271C10.1484 1.28809 8.18493 1.11484 6.39743 1.29359C4.31568 1.50396 2.21468 2.21759 0.905683 2.81296C0.785575 2.86759 0.683724 2.95562 0.612286 3.06656C0.540849 3.17749 0.502844 3.30664 0.502808 3.43859V18.5636C0.502839 18.6786 0.531734 18.7918 0.586844 18.8928C0.641954 18.9937 0.721517 19.0793 0.818247 19.1415C0.914977 19.2038 1.02578 19.2408 1.14051 19.2491C1.25524 19.2574 1.37022 19.2368 1.47493 19.1892C2.68768 18.6392 4.64156 17.9778 6.53493 17.7867C8.47231 17.5915 10.0962 17.9063 10.9666 18.9926C11.031 19.0729 11.1126 19.1377 11.2054 19.1822C11.2982 19.2267 11.3999 19.2499 11.5028 19.2499C11.6058 19.2499 11.7074 19.2267 11.8002 19.1822C11.893 19.1377 11.9746 19.0729 12.0391 18.9926C12.9094 17.9063 14.5333 17.5915 16.4693 17.7867C18.3641 17.9778 20.3193 18.6392 21.5307 19.1892C21.6354 19.2368 21.7504 19.2574 21.8651 19.2491C21.9798 19.2408 22.0906 19.2038 22.1874 19.1415C22.2841 19.0793 22.3637 18.9937 22.4188 18.8928C22.4739 18.7918 22.5028 18.6786 22.5028 18.5636V3.43859C22.5028 3.30664 22.4648 3.17749 22.3933 3.06656C22.3219 2.95562 22.22 2.86759 22.0999 2.81296C20.7909 2.21759 18.6899 1.50396 16.6082 1.29359C14.8207 1.11346 12.8572 1.28809 11.5028 2.45271Z" fill="white"/>
</svg>
      Related Novels</p>
      <div className="Related-novels-wrapper">
        <button onClick={scrollLeft} className="nav-button lft-btn-Related"><img src='../leftArrow.svg'></img></button>
        <div ref={containerRef} className='Related-novels-container'>
          {loading ? (
            <p>Loading...</p>
          ) : (
            relatedNovels.map((novel, index) => (
              <div key={index} className="Related-novel" onClick={() => handleBookClick(novel)}>
                <img
                  src={novel._source.Bookcover}
                  alt={`Cover of ${novel._source['Book title']}`}
                  onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.PUBLIC_URL}/placehold.svg`; }}
                />
                <div className="Related-novel-info">
                  <i><p className="title">{novel._source['Book title']}</p></i>
                  <p>{novel._source['Name as it appears']}</p>
                  <p>{novel._source['Publication year']}</p>
                  <div className="tagbuttons">
                    {novel._source.Categories && novel._source.Categories.slice(0, 2).map((category, categoryIndex) => (
                      <TagButton key={categoryIndex} onClick={() => handleTagClick(category)}>
                        {category}
                      </TagButton>
                    ))}
                  </div>
                </div>
              </div>

            ))
          )}
        </div>
        <button onClick={scrollRight} className="nav-button rt-btn-Related"><img src='../rightArrow.svg'></img></button>
      </div>
    </div>
  );
};

export default RelatedNovelsForDatavis;
